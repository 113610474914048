<template>
  <i-modal
    max-width="1200px"
    :title="
      $t(edit ? 'editing' : 'add', {
        value: $tc('email_setting.email_SMTP_setting', 1)
      })
    "
    :value="value"
    :load="loading"
    @submit="submit"
    @input="$emit('input', $event)"
  >
    <v-row class="my-0">
      <v-col class="d-flex g-5" cols="12">
        <!-- Switch: Por defecto -->
        <v-switch
          class="v-switch mt-0"
          :label="$t('email_setting.default_setting')"
          v-model="config.default_setting"
        ></v-switch>

        <!-- Switch: TLS -->
        <v-switch
          class="v-switch mt-0"
          :label="$t('email_setting.use_tls')"
          v-model="config.use_tls"
        ></v-switch>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <ValidationProvider
          vid="type_smtp"
          :name="$t('email_setting.smtp_type')"
          rules="required"
          v-slot="{ errors }"
        >
          <v-select
            outlined
            autocomplete="off"
            key="type_smtp-input"
            :items="type_smtp"
            :error-messages="errors[0]"
            v-model="config.type_smtp"
            color="secondary"
            :label="$t('email_setting.smtp_type')"
            @input="changeForm"
          />
        </ValidationProvider>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <ValidationProvider
          vid="host"
          :name="$t('email_setting.host')"
          rules=""
          v-slot="{ errors }"
        >
          <v-text-field
            outlined
            v-model="config.host"
            :error-messages="errors[0]"
            color="secondary"
            :label="$t('email_setting.host')"
          />
        </ValidationProvider>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <ValidationProvider
          vid="email"
          :name="$tc('email', 1)"
          rules="email"
          v-slot="{ errors }"
        >
          <v-text-field
            outlined
            v-model="config.account"
            :error-messages="errors[0]"
            color="secondary"
            :label="$tc('email', 1)"
          />
        </ValidationProvider>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <ValidationProvider
          vid="password"
          :name="$tc('password', 1)"
          rules="required"
          v-slot="{ errors }"
        >
          <v-text-field
            outlined
            v-model="config.password"
            :error-messages="errors[0]"
            color="secondary"
            :label="$tc('password', 1)"
            type="password"
          />
        </ValidationProvider>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <ValidationProvider
          vid="host_port"
          :name="$t('email_setting.host_port')"
          rules="numeric"
          v-slot="{ errors }"
        >
          <v-text-field
            outlined
            v-model="config.host_port"
            :error-messages="errors[0]"
            color="secondary"
            :label="$t('email_setting.host_port')"
          />
        </ValidationProvider>
      </v-col>
    </v-row>
    <v-expansion-panels v-if="config.type_smtp">
      <v-expansion-panel>
        <v-expansion-panel-header>
          <span v-show="config.type_smtp === 'Gmail'" key="header-gmail">
            {{ $t('email_setting.gmail.setting_gmail') }}
          </span>
          <span v-show="config.type_smtp === 'OutLook'" key="header-outlook">
            {{ $t('email_setting.outlook.setting_outlook') }}
          </span>
          <span v-show="config.type_smtp === 'Other'" key="header-other">
            {{ $t('email_setting.other.setting_other') }}
          </span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div v-show="config.type_smtp === 'Gmail'" key="content-gmail">
            <p>{{ $t('email_setting.gmail.msg1') }}</p>
            <ul>
              <li>
                {{ $t('email_setting.gmail.msg2') }}
                <a
                  href="https://myaccount.google.com/apppasswords"
                  target="blank"
                >
                  https://myaccount.google.com/apppasswords
                </a>
              </li>
              <li>
                {{ $t('email_setting.gmail.msg3') }} {{ route }}
                <v-img class="my-3" :src="imageGoogle"></v-img>
              </li>
              <li>
                {{ $t('email_setting.gmail.msg4') }}
              </li>
            </ul>
          </div>
          <div v-show="config.type_smtp === 'OutLook'" key="content-outlook">
            <p>{{ $t('email_setting.outlook.msg1') }}</p>
            <ul>
              <li>
                {{ $t('email_setting.outlook.msg2') }}
                <a
                  href="https://go.microsoft.com/fwlink/p/?linkid=858201"
                  target="blank"
                >
                  https://go.microsoft.com/fwlink/p/?linkid=858201
                </a>
              </li>
              <li>
                {{ $t('email_setting.outlook.msg3') }}
                <v-img class="my-3" :src="imageOutlook"></v-img>
              </li>
              <li>
                {{ $t('email_setting.outlook.msg4') }}
              </li>
            </ul>
          </div>
          <div v-show="config.type_smtp === 'Other'" key="content-other">
            <p>{{ $t('email_setting.other.msg1') }}</p>
            <ul>
              <li>
                {{ $t('email_setting.other.msg2') }}
              </li>
            </ul>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </i-modal>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    toEdit: {
      type: [Object],
      default: () => null
    },
    main: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dialog: false,
      loading: false,
      credentials: [],
      edit: false,
      config: {
        id: '',
        company: '',
        default_setting: true,
        type_smtp: '',
        account: '',
        password: '',
        host: '',
        host_port: '',
        use_tls: true,
        fixed: false
      },
      defaultIprintSMTP: false,
      credential: {
        gmail: {
          id: '',
          company: '',
          default_setting: true,
          type_smtp: 'Gmail',
          account: '',
          password: '',
          host: 'smtp.gmail.com',
          host_port: '587',
          use_tls: true,
          fixed: false
        },
        outlook: {
          id: '',
          company: '',
          default_setting: true,
          type_smtp: 'OutLook',
          account: '',
          password: '',
          host: 'SMTP.Office365.com',
          host_port: '587',
          use_tls: true,
          fixed: false
        },
        other: {
          id: '',
          company: '',
          default_setting: true,
          type_smtp: 'Other',
          account: '',
          password: '',
          host: '',
          host_port: '',
          use_tls: true,
          fixed: false
        }
      },
      imageGoogle: require('@/assets/GoogleLessSecureApps.png'),
      imageOutlook: require('@/assets/AllowDevicesUsePOPOutLook.png'),
      google: false,
      outlook: false,
      type_smtp: [
        {
          text: this.$t('email_setting.gmail.gmail'),
          value: 'Gmail'
        },
        {
          text: this.$t('email_setting.outlook.outlook'),
          value: 'OutLook'
        },
        {
          text: this.$t('other'),
          value: 'Other'
        }
      ],
      route: location.protocol + '//' + location.host
    }
  },
  computed: {
    ...mapGetters({
      company: 'company/getCompanyData'
    })
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        if (val) {
          this.edit = this.toEdit !== null
          this.config =
            this.toEdit !== null
              ? { ...this.toEdit }
              : {
                  id: '',
                  company: '',
                  default_setting: true,
                  type_smtp: '',
                  account: '',
                  password: '',
                  host: '',
                  host_port: '',
                  use_tls: true,
                  fixed: false
                }
        }
      }
    }
  },
  methods: {
    async submit() {
      if (!this.loading) {
        try {
          this.loading = true
          if (!this.edit) {
            this.config.company = this.company.pk
            this.config.main = this.main
            await this.$api.email.config.create({ form: this.config })
          } else {
            await this.$api.email.config.edit({
              pk: this.config.id,
              form: this.config
            })
          }
          this.$emit('done')
          this.$emit('input', false)
          this.$toast.success(
            `${this.$tc('email_setting.email_SMTP_setting', 1)} ${this.$tc(
              this.edit ? 'edited' : 'created',
              1
            )}`
          )
        } catch (error) {
          console.error(error)
        } finally {
          this.loading = false
        }
      }
    },
    changeForm() {
      if (this.config.type_smtp === 'Gmail') {
        this.config = Object.assign({}, this.credential.gmail)
      }
      if (this.config.type_smtp === 'OutLook') {
        this.config = { ...this.credential.outlook }
      }
      if (this.config.type_smtp === 'Other') {
        this.config = JSON.parse(JSON.stringify(this.credential.other))
      }
    }
  }
}
</script>
